export function GroupKanbanList(orders) {
  let list = {
    OL: {
      id: 'OL',
      name: 'Open Leads',
      cardIds: []
    },
    CIM: {
      id: 'CIM',
      name: 'Call & Initial Mail',
      cardIds: []
    },
    RETC: {
      id: 'RETC',
      name: 'Reminder Email, Text & Call',
      cardIds: []
    },
    DFIC: {
      id: 'DFIC',
      name: 'Data forward to Insurance Company',
      cardIds: []
    },
    PSWC: {
      id: 'PSWC',
      name: 'Proposals Shared with Client',
      cardIds: []
    },
    C: {
      id: 'C',
      name: 'Confirmations',
      cardIds: []
    },
    FTC: {
      id: 'FTC',
      name: 'Failed to Close',
      cardIds: []
    },
    NR: {
      id: 'NR',
      name: 'No Response',
      cardIds: []
    }
  };
  if (orders.length !== 0) {
    orders.map(order => {
      switch (order.status) {
        case 'Open Leads':
          if (
            list.OL.cardIds.filter(idss => idss === order.id.toString())
              .length === 0
          ) {
            list = {
              ...list,
              OL: {
                ...list.OL,
                cardIds: [...list.OL.cardIds, order.id.toString()]
              }
            };
          }
          break;
        case 'Call & Initial Mail':
          if (
            list.CIM.cardIds.filter(idss => idss === order.id.toString())
              .length === 0
          ) {
            list = {
              ...list,
              CIM: {
                ...list.CIM,
                cardIds: [...list.CIM.cardIds, order.id.toString()]
              }
            };
          }
          break;
        case 'Reminder Email, Text & Call':
          if (
            list.RETC.cardIds.filter(idss => idss === order.id.toString())
              .length === 0
          ) {
            list = {
              ...list,
              RETC: {
                ...list.RETC,
                cardIds: [...list.RETC.cardIds, order.id.toString()]
              }
            };
          }
          break;
        case 'Data forward to Insurance Company':
          if (
            list.DFIC.cardIds.filter(idss => idss === order.id.toString())
              .length === 0
          ) {
            list = {
              ...list,
              DFIC: {
                ...list.DFIC,
                cardIds: [...list.DFIC.cardIds, order.id.toString()]
              }
            };
          }
          break;
        case 'Proposals Shared with Client':
          if (
            list.PSWC.cardIds.filter(idss => idss === order.id.toString())
              .length === 0
          ) {
            list = {
              ...list,
              PSWC: {
                ...list.PSWC,
                cardIds: [...list.PSWC.cardIds, order.id.toString()]
              }
            };
          }
          break;
        case 'Confirmations':
          if (
            list.C.cardIds.filter(idss => idss === order.id.toString())
              .length === 0
          ) {
            list = {
              ...list,
              C: {
                ...list.C,
                cardIds: [...list.C.cardIds, order.id.toString()]
              }
            };
          }
          break;
        case 'Failed to Close':
          if (
            list.FTC.cardIds.filter(idss => idss === order.id.toString())
              .length === 0
          ) {
            list = {
              ...list,
              FTC: {
                ...list.FTC,
                cardIds: [...list.FTC.cardIds, order.id.toString()]
              }
            };
          }
          break;
        case 'No Response':
          if (
            list.NR.cardIds.filter(idss => idss === order.id.toString())
              .length === 0
          ) {
            list = {
              ...list,
              NR: {
                ...list.NR,
                cardIds: [...list.NR.cardIds, order.id.toString()]
              }
            };
          }
          break;
      }
    });

    return list;
  } else {
    return list;
  }
}

export function GroupKanbanCard(orders) {
  let cards = {};
  let listId = 'OL';
  if (orders.length !== 0) {
    orders.map(order => {
      switch (order.status) {
        case 'Open Leads':
          listId = 'OL';
          break;
        case 'Call & Initial Mail':
          listId = 'CIM';
          break;
        case 'Reminder Email, Text & Call':
          listId = 'RETC';
          break;
        case 'Data forward to Insurance Company':
          listId = 'DFIC';
          break;
        case 'Proposals Shared with Client':
          listId = 'PSWC';
          break;
        case 'Confirmations':
          listId = 'C';
          break;
        case 'Failed to Close':
          listId = 'FTC';
          break;
        case 'No Response':
          listId = 'NR';
          break;
      }
      let order_id = order.id;
      return (cards = {
        ...cards,
        [order_id.toString()]: {
          ...order,
          //   cover: '/static/images/projects/project_3.png',
          isSubscribed: false,
          name: order.order_number,
          listId
        }
      });
    });
    return cards;
  } else {
    return cards;
  }
}

export function GroupKanbanMembers(staff) {
  let members = {};
  if (staff.length !== 0) {
    staff.map(s => {
      return (members = { ...members, [s.id]: s });
    });
    return members;
  } else {
    return members;
  }
}

export let list_kanaban = {
  OL: {
    id: 'OL',
    name: 'Open Leads',
    cardIds: []
  },
  CIM: {
    id: 'CIM',
    name: 'Call & Initial Mail',
    cardIds: []
  },
  RETC: {
    id: 'RETC',
    name: 'Reminder Email, Text & Call',
    cardIds: []
  },
  DFIC: {
    id: 'DFIC',
    name: 'Data forward to Insurance Company',
    cardIds: []
  },
  PSWC: {
    id: 'PSWC',
    name: 'Proposals Shared with Client',
    cardIds: []
  },
  C: {
    id: 'C',
    name: 'Confirmations',
    cardIds: []
  },
  FTC: {
    id: 'FTC',
    name: 'Failed to Close',
    cardIds: []
  },
  NR: {
    id: 'NR',
    name: 'No Response',
    cardIds: []
  }
};
