export const API_LOADING = 'API_LOADING';
export const API_RETURN_MSG = 'API_RETURN_MSG';
export const API_CHANGE_VALUE = 'API_CHANGE_VALUE';
export const API_RESET = 'API_RESET';
export const API_EDIT = 'API_EDIT';

export const CREATE_UPDATE = 'CREATE_UPDATE';
export const LIST = 'LIST';
export const GET = 'GET';
export const SCAN = 'SCAN';
