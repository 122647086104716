import React from 'react';

function Logo(props) {
  // <img
  //   alt="Logo"
  //   src="/static/logo.svg"
  //   {...props}
  // />
  if (props.style || props.className) {
    return <img alt="Logo" src="/static/logo-icon.png" {...props} />;
  } else {
    return (
      <img
        alt="Logo"
        src="/static/logo-icon.png"
        {...props}
        style={{ width: 42, height: 42 }}
      />
    );
  }
}

export default Logo;
